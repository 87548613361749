import { render, staticRenderFns } from "./FormChangePersonalData.vue?vue&type=template&id=6885e53e&scoped=true&"
import script from "./FormChangePersonalData.vue?vue&type=script&lang=js&"
export * from "./FormChangePersonalData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6885e53e",
  null
  
)

export default component.exports